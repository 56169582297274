.App {
  text-align: center;
}

.App-logo {
  height: 7vmin;
  pointer-events: none;
}

.App-img {

  margin: 10px;
  height: 25vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Nav-link{
  font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    min-width: 64px;
    padding: 6px 8px;
    margin-top: 16px;
    margin-bottom: 16px;
    color: white;
    display: block;
    text-decoration: none;
}
.active{
  border-bottom: 2px solid skyblue;
}
.color-white{
  color: white;
}


.color-white a{
  text-decoration: none;
}


.color-bluesky{
  color: #3989d9;
  text-decoration: none;
}


.color-bluesky a{
  text-decoration: none;
}



.color-bluesky .a{
  text-decoration: none;
}



.color-red{
  color: red;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
